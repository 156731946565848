const colors = [
  'white',
  'titan-white',
  'wild-sand',
  'lavendar-blush',
  'moon-raker',
  'bridal-heath',
  'beige',
  'cream',
  'half-and-half',
  'lemon-chiffon',
  'corn-field',
  'double-colonial-white',
  'ivory',
  'half-spanish-white',
  'navajo-white',
  'wheat',
  // ---- //
  'seashell',
  'mystic',
  'gallery',
  'alto',
  'quill-gray',
  'silver-sand',
  'silver',
  'silver-chalice',
  'silver-chalice-dark',
  'jumbo',
  'limed-ash',
  'gray',
  'dove-gray',
  'scorpion',
  'soya-bean',
  'slate-gray',
  'storm-gray',
  'tuna',
  'black',
  'cavern-pink',
  'vanilla-ice',
  'pastel-pink',
  'pink',
  'carnation-pink',
  'hot-pink',
  'charm',
  'cerise',
  'lavendar',
  'persian-rose',
  'amaranth',
  'red-violet',
  'plum',
  'razzle-dazzle-rose',
  'pink-flamingo',
  'thistle',
  'cold-purple',
  'light-orchid',
  'orchid',
  'lavendar-magenta',
  'magenta',
  'amethyst',
  'royal-purple',
  'purple-heart',
  'electric-violet',
  'blue-gem',
  'flirt',
  'fresh-eggplant',
  'purple-pizzazz',
  'violet-eggplant',
  'seance',
  'daisy-bush',
  'medium-purple',
  'portage',
  'blue-marguerite',
  'gigas',
  'eminence',
  'pigment-indigo',
  'scarlet-gum',
  'martinique',
  'blackcurrent',
  'fuchsia-blue',
  'thunder',
  'cocoa-brown',
  'wine-berry',
  'indigo',
  'violet',
  'pippin',
  'peach-schnapps',
  'quicksand',
  'vivid-tangerine',
  'salmon',
  'froly',
  'chestnut-rose',
  'pomegranate',
  'red',
  'mexican-red',
  'roof-terracotta',
  'crimson',
  'red-berry',
  'seashell-peach',
  'champagne',
  'linen',
  'sorrell-brown',
  'old-lace',
  'varden',
  'sandy-beach',
  'negroni',
  'calico',
  'tan',
  'papaya-whip',
  'peach',
  'frangipani',
  'wax-flower',
  'copperfield',
  'apricot',
  'web-orange',
  'orange-peel',
  'pizzazz',
  'west-side',
  'gamboge',
  'hot-cinnamon',
  'orange',
  'persimmon',
  'vermilion',
  'coral',
  'burnt-sienna',
  'sandy-brown',
  'chalky',
  'gorse',
  'yellow',
  'chenin',
  'zombie',
  'starship',
  'macaroni-and-cheese',
  'amber',
  'gold',
  'anzac',
  'cream-can',
  'saffron',
  'khaki',
  'coral-reef',
  'gimblet',
  'husk',
  'gurkha',
  'snowy-mint',
  'hint-of-green',
  'norway',
  'sushi',
  'chelsea-cucumber',
  'mint-green',
  'pastel-green',
  'green',
  'apple',
  'fruit-salad',
  'salem',
  'spring-green',
  'caribbean-green',
  'ocean-green',
  'sea-green',
  'forest-green',
  'japanese-laurel',
  'summer-green',
  'veridian-green',
  'camarone',
  'parsley',
  'pear',
  'atlantis',
  'green-yellow',
  'chartreuse',
  'lima',
  'olive-drab',
  'costa-del-sol',
  'olive',
  'teal',
  'persian-green',
  'eastern-blue',
  'pine-green',
  'oracle',
  'boston-blue',
  'eden',
  'elephant',
  'cyan',
  'sinbad',
  'anakiwa',
  'blizzard-blue',
  'charlotte',
  'downie',
  'bright-turquoise',
  'baby-blue',
  'robin-eggs-blue',
  'aquamarine',
  'viking',
  'puerto-rico',
  'blue-chill',
  'turquoise',
  'patina',
  'breaker-bay',
  'steel-blue',
  'cornflour-blue',
  'cerulean',
  'dodger-blue',
  'fountain-blue',
  'glacier',
  'royal-blue',
  'tranquil',
  'mabel',
  'spindle',
  'seagull',
  'regent-st-blue',
  'powder-blue',
  'malibu',
  'cornflour',
  'blue',
  'dark-blue',
  'navy-blue',
  'lucky-point',
  'blue-charcoal',
  'gulf-blue',
  'marshland',
  'lynch',
  'spectra',
  'bali-hai',
  'nevada',
  'blue-bayoux',
  'periwinkle-gray',
  'pigeon-post',
  'ebony-clay',
  'charade',
  'clinker',
  'hot-tody',
  'raw-sienna',
  'copper-canyon',
  'paarl',
  'roman-coffee',
  'shingle-fawn',
  'golden-grass',
  'maroon',
  'cocoa-bean',
];

const whiteColors = [
  'white',
  'seashell',
  'titan-white',
  'wild-sand',
  'lavendar-blush',
  'moon-raker',
  'bridal-heath',
  'beige',
  'cream',
  'half-and-half',
  'lemon-chiffon',
  'corn-field',
  'double-colonial-white',
  'ivory',
  'half-spanish-white',
  'navajo-white',
  'wheat',
];

export default colors;