import React from 'react';

const YarnIcon = () => {

  return (
    <svg width="160" height="90" viewBox="0 0 700 500">
      <path fill="#ffffff" d="M399.8,325.4c-4.2,14.3-10.8,24.7-18.3,33v-72.9c0-5-4.4-9.1-11.2-9.1c-2.9,0-5.4,1.5-5.4,3.5
	c0,1.5,0.8,2.7,0.8,6.7V320c-2.5,14.5-8.7,28.1-17,28.1c-6,0-9.6-6-9.6-17.2c0-17.5,2.3-26.6,6-42c0.8-3.1,6.9-11.4-3.3-11.4
	c-11,0-9.6,4.2-11,7.7c0,0-7,24.7-7,46.8c0,18.1,7.6,29.9,21.5,29.9c8.9,0,15.4-6,20.4-14.3v26.4c-13.7,12.1-25.8,22.7-25.8,43.6
	c0,13.3,8.3,23.9,20,23.9c10.6,0,21.6-7.7,21.6-29.5v-36c11.2-9.8,23.3-22,30.3-46.1c0.2-0.8,0.2-1.9,0.2-2.1c0-3.9-3.9-8.5-7.3-8.5
	C402.7,319.1,401,321,399.8,325.4L399.8,325.4z M359.9,428.3c-3.3,0-5.4-5-5.4-11.4c0-12.5,4.6-20.4,11.2-27.2v22.2
	C365.7,415.8,366.5,428.3,359.9,428.3L359.9,428.3z M459.4,347.8c-5,0-7.1-5-7.1-9.6V304c0-5-4.4-9.1-11.2-9.1
	c-2.9,0-5.4,1.5-5.4,3.5c0,1.5,0.8,2.7,0.8,6.7v32c-2.3,5.8-6,10.8-11.6,10.8c-7.3,0-11.8-6.2-11.8-17c0-29.9,18.5-43.4,34.3-43.4
	c2.1,0,4.2,0.4,6,0.4c2.1,0,2.7-1.2,2.7-4.8c0-5.4-3.9-8.7-9.6-8.7c-25.4,0-49.5,21.2-49.5,55.9c0,17.7,8.5,31.4,24.7,31.4
	c7.9,0,13.7-3.7,17.9-8.5c3.1,5,8.7,8.5,16,8.5c17.9,0,26.2-18.7,29.7-32.4c0.2-0.8,0.2-1.2,0.2-1.5c0-3.9-3.9-8.5-7.3-8.5
	c-2.1,0-4.2,1.9-5,6.2C471.5,334.5,467.7,347.8,459.4,347.8L459.4,347.8z M519.5,361.5c17.9,0,26-18.7,29.7-32.4
	c0-0.4,0.2-0.8,0.2-1.5c0-3.9-3.9-8.5-7.3-8.5c-2.1,0-4.2,1.9-5,6.2c-1.9,9.1-5.4,22.4-15,22.4c-5.6,0-8.3-5.4-8.3-11.2
	c0-20.8,9.4-45.3,9.4-47.8c0.8-4.8-7.5-11.6-10-11.6h-10.8c-2.1,0-4.2,0-11-0.8c-2.3-8.5-8.1-11-13.1-11c-5.4,0-10.4,3.7-10.4,9.6
	c0,6,3.7,10.4,8.9,13.3c-0.2,10.6-1,27.8-3.3,36.2c-1.9,7.1,8.9,14.5,11.6,5.8c3.7-12.1,5-30.1,5.2-38.2h18.1
	c-6.7,17.9-10.4,32.6-10.4,45.9C498.1,356.1,509.7,361.5,519.5,361.5L519.5,361.5z M538,352.4c0,7.7,5.8,9.1,10,9.1
	c6,0,5.8-5,5.8-8.9v-30.3c1.5-16.4,14.3-34.3,20.4-34.3c3.9,0,4.4,5.4,4.4,11.8V342c0,10.6,6.4,19.5,17.5,19.5
	c17.9,0,26.7-18.7,30.2-32.4c0.2-0.8,0.2-1.2,0.2-1.5c0-3.9-3.9-8.5-7.3-8.5c-2.1,0-4.2,1.9-5,6.2c-1.9,9.1-6.1,22.4-14.4,22.4
	c-5.4,0-5.4-7.7-5.4-9.6v-43c0-9.6-3.3-21-17.2-21c-10.2,0-17.7,8.9-23.3,20.6v-9.4c0-5-4.4-9.1-11.2-9.1c-2.9,0-5.4,1.5-5.4,3.5
	c0,1.5,0.8,2.7,0.8,6.7L538,352.4L538,352.4z M161,191.4c74.3,0,134.6,60.3,134.6,134.6S235.3,460.6,161,460.6S26.4,400.3,26.4,326
	S86.7,191.4,161,191.4z M252.5,366.8c-0.9-7.4-7.2-12.5-15.2-12.4c-12,0.2-22,6.3-28.6,10.4c-2.6,1.6-4.8,2.8-6.8,3.7
	c0.4-6,0.1-13.9-3.1-22.6c-3.8-10.4-8.9-16.8-12.5-20.5c4.2-6.1,10-15.1,12.7-28.9c2.3-11.8,1.6-30.1-3.7-40.4
	c-1.1-2.1-2.9-3.6-5.2-4.2c-0.9-0.3-2.7-0.8-6.2,0.2c-5.2-10.9-7.1-12-8.5-12.9c-2.9-1.9-6.3-2.3-9.6-1.1c-4.3,1.6-8,5.7-11.5,13.1
	c-0.5,1.1-1,2.1-1.4,3.2c-6.6,0.5-17,2.9-25.8,12.4c-1.1,1.2-3.2,2.1-5.5,2.9h0.1c-4.6,1.6-6.7,5.4-9.2,12.1
	c-3.5,9.5,0.1,18.8,3.7,24.8c-4.9,4.4-11.4,11.3-14.8,19.5c-4.3,10.1-4.7,20-4.6,25.3c-3.6,3.8-9.2,11.1-9.9,19.2
	c-0.8,11.3,3.3,19,5.1,21.8c0.5,0.8,1.1,1.5,1.7,2.2c-0.2,1.4-0.3,2.9,0.1,4.5c0.7,3.6,3,6.6,6.4,8.5c6.9,3.6,16.4,5.2,23.8,1.5
	c2.6,2.8,7.5,5.5,16.3,5.5h0.5c2.2,0,30.6-1.5,38.9-3.5c3.7-0.9,6.2-2.4,7.9-3.8c5.3-1.7,20-6.7,33.8-15.6
	c9.8-6.3,13.1-7.7,20.4-9.5C249,380.3,253.4,373.9,252.5,366.8L252.5,366.8z M240.2,374.4c-8.3,2-12.5,3.8-22.8,10.5
	c-16.1,10.4-33.6,15.2-33.6,15.2s-1.5,2.2-5.7,3.2c-7.3,1.8-34.7,3.3-37.1,3.3c-6.7,0.1-10.8-1.7-12-4.5c-3.5-8.3,5-12,5-12
	s-1.9-1.1-3-2.2c-1-1-2-3-2.3-2.2c-1.3,3.2-2,10.9-5.5,14.4c-4.8,4.8-13.8,3.2-19.2,0.4c-5.9-3.1,0.4-10.4,0.4-10.4s-3.2,1.9-5.7-2
	c-2.3-3.5-4.4-9.6-3.8-17c0.6-8.5,10.1-16.7,10.1-16.7s-1.7-12.5,3.8-25.4c4.9-11.7,18.2-21.1,18.2-21.1s-11.2-12.4-7-23.5
	c2.7-7.3,3.8-7.2,4.7-7.5c3.1-1.2,6.1-2.5,8.4-4.9c11.2-12.1,25.4-9.8,25.4-9.8s6.8-20.5,13-16.5c1.9,1.2,8.8,16.6,8.8,16.6
	s7.4-4.3,8.2-2.7c4.5,8.7,5,25.3,3,35.3c-3.3,16.6-11.6,25.6-15,31.2c-0.8,1.3,8.9,5.4,15.1,22.4c5.7,15.5,0.6,28.6,1.5,30
	c0.2,0.3,0.2,0.4,0.2,0.4s6.5,0.5,19.5-7.5c7-4.3,15.2-9.1,24.6-9.2C246.5,362.1,247,372.8,240.2,374.4L240.2,374.4z"/>
    </svg>

  );
}

export default YarnIcon;
