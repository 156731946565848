import React from 'react';

const GithubIcon = () => {

  return (
    <svg width="180" height="90" viewBox="0 0 100 10" fill="#ffffff">
      <path d="M39.8,11.13H35a.23.23,0,0,0-.23.22v2.36a.23.23,0,0,0,.23.22h1.88v2.93a5.83,5.83,0,0,1-1.59.14c-1.38,0-3.3-.5-3.3-4.73s2-4.78,3.88-4.78a7.9,7.9,0,0,1,2.78.42.22.22,0,0,0,.27-.22l.53-2.27a.23.23,0,0,0-.08-.18c-.18-.13-1.29-.75-4.08-.75-3.21,0-6.51,1.37-6.51,8s3.77,7.55,7,7.55A7.88,7.88,0,0,0,40,18.87.21.21,0,0,0,40,18.7V11.35A.22.22,0,0,0,39.8,11.13Z"></path>
      <path d="M64.61,5.28a.22.22,0,0,0-.22-.22H61.68a.23.23,0,0,0-.23.22v5.24H57.23V5.28A.22.22,0,0,0,57,5.06H54.29a.22.22,0,0,0-.22.22V19.47a.22.22,0,0,0,.22.23H57a.22.22,0,0,0,.22-.23V13.4h4.23v6.07a.22.22,0,0,0,.22.23h2.72a.22.22,0,0,0,.22-.23Z"></path>
      <path d="M44.91,7.14a1.75,1.75,0,1,0-1.75,1.77A1.75,1.75,0,0,0,44.91,7.14Z"></path>
      <path d="M44.72,16.48V9.93a.23.23,0,0,0-.23-.23h-2.7a.26.26,0,0,0-.23.25v9.39c0,.27.17.36.39.36h2.44c.26,0,.33-.13.33-.37Z"></path>
      <path d="M74.92,9.72H72.23A.23.23,0,0,0,72,10v7a3.08,3.08,0,0,1-1.66.5c-1,0-1.23-.44-1.23-1.39V10a.23.23,0,0,0-.22-.23H66.17a.24.24,0,0,0-.23.23v6.53c0,2.82,1.58,3.51,3.74,3.51a6.31,6.31,0,0,0,3.21-1,3.93,3.93,0,0,0,.1.58.23.23,0,0,0,.19.12h1.74a.23.23,0,0,0,.23-.22V10A.23.23,0,0,0,74.92,9.72Z"></path>
      <path d="M82.27,9.41a5.06,5.06,0,0,0-2.56.68V5.28a.22.22,0,0,0-.23-.22H76.76a.22.22,0,0,0-.22.22V19.47a.22.22,0,0,0,.22.23h1.89a.24.24,0,0,0,.2-.12,5.63,5.63,0,0,0,.11-.66A4.85,4.85,0,0,0,82.18,20c2.47,0,3.89-1.26,3.89-5.63S83.8,9.41,82.27,9.41Zm-1.06,8A3.23,3.23,0,0,1,79.65,17v-4.5A3.59,3.59,0,0,1,81,12c1-.08,1.9.21,1.9,2.52S82.52,17.44,81.21,17.4Z"></path>
      <path d="M52.51,9.7h-2V7c0-.1,0-.15-.17-.15H47.53a.14.14,0,0,0-.16.15V9.79l-1.49.36a.24.24,0,0,0-.16.22v1.74a.23.23,0,0,0,.23.23h1.42v4.2C47.37,19.66,49.55,20,51,20a6.19,6.19,0,0,0,1.61-.26.21.21,0,0,0,.13-.21V17.57a.22.22,0,0,0-.22-.22c-.12,0-.42,0-.73,0-1,0-1.34-.47-1.34-1.07v-4h2a.23.23,0,0,0,.23-.23V9.93A.23.23,0,0,0,52.51,9.7Z"></path>
      <path d="M12.55,0a12.56,12.56,0,0,0-4,24.47c.63.11.86-.28.86-.61s0-1.29,0-2.34C5.93,22.28,5.2,20,5.2,20a3.28,3.28,0,0,0-1.4-1.83c-1.14-.78.09-.77.09-.77a2.63,2.63,0,0,1,1.92,1.3,2.68,2.68,0,0,0,3.66,1,2.68,2.68,0,0,1,.79-1.68c-2.79-.32-5.72-1.39-5.72-6.2a4.84,4.84,0,0,1,1.3-3.37A4.51,4.51,0,0,1,6,5.21s1-.34,3.45,1.28a12,12,0,0,1,6.29,0c2.39-1.62,3.45-1.28,3.45-1.28a4.51,4.51,0,0,1,.12,3.32,4.83,4.83,0,0,1,1.29,3.37c0,4.82-2.93,5.88-5.73,6.19a3,3,0,0,1,.85,2.33c0,1.68,0,3,0,3.44s.22.73.86.6A12.55,12.55,0,0,0,12.55,0Z"></path>
      <path d="M4.75,18c0,.07-.12.09-.21,0s-.14-.12-.11-.19.12-.08.21,0,.15.13.11.19Zm-.15-.11"></path>
      <path d="M5.26,18.59c-.06.06-.17,0-.25-.06a.18.18,0,0,1,0-.26.2.2,0,0,1,.26.06c.08.09.1.21,0,.26Zm-.12-.13"></path>
      <path d="M5.76,19.31c-.08.06-.2,0-.28-.1s-.08-.25,0-.3.2,0,.28.1.08.25,0,.3Zm0,0"></path>
      <path d="M6.44,20c-.07.08-.22.06-.33-.05A.24.24,0,0,1,6,19.64a.26.26,0,0,1,.33.05c.11.1.14.25.07.32Zm0,0"></path>
      <path d="M7.37,20.42c0,.1-.17.14-.31.1s-.24-.16-.21-.26.17-.14.32-.1.23.16.2.26Zm0,0"></path>
      <path d="M8.4,20.49c0,.11-.12.19-.27.19s-.27-.08-.27-.18.12-.19.27-.19.27.08.27.18Zm0,0"></path>
      <path d="M9.36,20.33c0,.1-.09.21-.24.23s-.28,0-.3-.13.09-.21.23-.24.29,0,.31.14Zm0,0"></path>
    </svg>

  );
}

export default GithubIcon;
